import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { BiLoaderAlt } from 'react-icons/bi';
import { Tooltip } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import {
	H2,
	H6TextSecondary,
	SmallTextSecondary,
	SmallTextSecondaryMedium,
	ParagraphMedium,
} from '../../../../styles/StylesV3Main';
import EditSubscription from './EditSubscription';
import numberToCurrencyString from '../../../../utils/number-to-currency-string';
import { useClient } from '../../../../hooks';
import config from '../../../../config';
import StyledLoading from '../../../../styles/LoadingStyles';

type TeamBillingWrapperProps = {
	team: any;
	handleUpdate: () => {};
};

const TeamBillingWrapper = ({
	team,
	handleUpdate,
}: TeamBillingWrapperProps) => {
	const [billing, setBilling] = useState<any | null>(null);
	const loadedBillingInfo = useRef(0);
	const [reactivated, setReactivated] = useState(false);
	const [showModalEditSubscription, setShowModalEditSubscription] =
		useState(false);
	const [updating, setUpdating] = useState<boolean>(false);
	const client = useClient();

	const retreiveBillingInfo = async () => {
		try {
			setBilling(null);
			const response = await client.get(
				`${config.platform.HOST}/payments/authorized/subscriptions/${team.id}`
			);
			if (response.data) {
				setBilling(response.data);
				setReactivated(false);
				setUpdating(false);
				setShowModalEditSubscription(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (loadedBillingInfo.current !== team.id) {
			retreiveBillingInfo();
			loadedBillingInfo.current = team.id;
		}
	}, [retreiveBillingInfo, team.id]);

	const cancelSub = async () => {
		try {
			client.put(`/stripe/subscription/${team.stripeSubscriptionId}/cancel`);
		} catch (err: any) {
			console.error(err.message);
		}
	};

	const reactivateSub = async () => {
		setReactivated(true);
		try {
			const response = await client.put(
				`/stripe/subscription/${team.stripeSubscriptionId}/reactivate`
			);
			if (response.status === 200 && response.data) {
				handleUpdate();

				await retreiveBillingInfo();
			}
		} catch (err: any) {
			console.error(err.message);
		}
	};

	const currentJunoDay = moment(billing?.pointsRenewalDate).format('D');

	const subscriptionUpdater = async (budgetStr: string, junoDay: number) => {
		try {
			const budget = +budgetStr;

			const response = await client.post(
				`${config.platform.HOST}/payments/authorized/subscriptions/${team.id}`,
				{ budget, junoDay }
			);

			if (response.status === 200) {
				handleUpdate();

				await retreiveBillingInfo();
			}
		} catch (err: any) {
			console.error(err.message);
		}
	};

	if (!billing) {
		return <StyledLoading height="20vh" />;
	}

	return (
		<TeamBillingContainer>
			<H2>Plans</H2>
			<Container>
				<SubscriptionContainer>
					<H6TextSecondary>Subscription • {billing?.name}</H6TextSecondary>
					<Row
						noGutters
						className="mt-3 justify-content-center align-items-end"
					>
						<NumberH1>{billing?.members}</NumberH1>
						<Text data-testid="members">members</Text>
					</Row>
					{!billing?.cancelAtPeriodEnd ? (
						<>
							{billing?.fees > 0 && (
								<Row
									noGutters
									className="mb-3 justify-content-center align-items-end"
								>
									<Col className="px-0 oy-0">
										<ParagraphMedium>
											{numberToCurrencyString(billing?.fees, billing?.currency)}{' '}
											{billing?.interval === 'month' ? 'Monthly' : 'Yearly'}{' '}
											(excl. VAT)
										</ParagraphMedium>
										<SmallTextSecondaryMedium>
											Renews{' '}
											{moment(billing?.renewalDate).format('MMMM DD, YYYY')}
										</SmallTextSecondaryMedium>
									</Col>
								</Row>
							)}
							<Row
								noGutters
								className=" justify-content-center align-items-end"
							>
								<SubButton
									as="a"
									href="https://meetings.hubspot.com/jack771/thinking-of-cancelling"
									target="_blank"
									rel="noopener noreferrer"
									onClick={cancelSub}
								>
									Thinking of leaving?
								</SubButton>
							</Row>
						</>
					) : (
						<Row
							noGutters
							className="mt-3 justify-content-center align-items-end"
						>
							<Col className="px-0 oy-0">
								<CancelledText>Cancelled</CancelledText>
								<SmallTextSecondary className="mt-2">
									Remains active until{' '}
									{moment(billing?.renewalDate).format('MMMM DD, YYYY')}
								</SmallTextSecondary>
								<SubButton
									data-testid="reactivate"
									className="mt-3"
									onClick={() => {
										if (!reactivated) {
											reactivateSub();
										}
									}}
								>
									{reactivated ? (
										<Spinner size={25} fill="#000" />
									) : (
										`Reactivate subscription`
									)}
								</SubButton>
							</Col>
						</Row>
					)}
				</SubscriptionContainer>
				<Divider />
				<MonthlyAllowanceContainer cancelled={billing?.cancelAtPeriodEnd}>
					<H6TextSecondary>Monthly Budget</H6TextSecondary>
					<Row
						noGutters
						className="mt-3 justify-content-center align-items-end"
					>
						<NumberH1>{billing?.budget}</NumberH1>
						<Text data-testid="points">points</Text>
					</Row>
					<Row noGutters className="justify-content-center align-items-end">
						<Col className="px-0 oy-0">
							<ParagraphMedium>
								{numberToCurrencyString(billing?.total, billing?.currency)}{' '}
								Monthly
								{billing?.customBudget && (
									<InfoTooltip
										placement="top"
										title="Some members in the team have a custom budget"
									>
										<button
											type="button"
											style={{ backgroundColor: 'transparent', border: 0 }}
										>
											<InfoIcon style={{ marginTop: '-4px' }} />
										</button>
									</InfoTooltip>
								)}
							</ParagraphMedium>
							<SmallTextSecondaryMedium>
								Next Juno Day →{' '}
								{moment(billing?.pointsRenewalDate).format('MMMM DD, YYYY')}
							</SmallTextSecondaryMedium>
						</Col>
					</Row>
					<Row
						noGutters
						className="justify-content-center align-items-end mt-3"
					>
						<SubButton
							width="50px"
							onClick={() => {
								setShowModalEditSubscription(true);
							}}
							disabled={billing?.cancelAtPeriodEnd}
						>
							Edit
						</SubButton>
					</Row>
				</MonthlyAllowanceContainer>
			</Container>
			{showModalEditSubscription && (
				<EditSubscription
					showModalEditSubscription={showModalEditSubscription}
					setShowModalEditSubscription={setShowModalEditSubscription}
					budget={billing.budget}
					currency={billing?.currency}
					currentJunoDay={currentJunoDay}
					subscriptionUpdater={subscriptionUpdater}
					updating={updating}
					setUpdating={setUpdating}
				/>
			)}
		</TeamBillingContainer>
	);
};

const InfoTooltip = withStyles({
	tooltip: {
		padding: '8px',
		backgroundColor: '#191A1C',
		fontFamily: 'Roobert Medium',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		textAlign: 'center',
		color: '#fff',
		width: '200px',
	},
})(Tooltip);

const TeamBillingContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: start;
	align-items: start;
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 50% 1px 50%;
	padding: 40px;
	width: 100%;
	height: 100%;
	background: #f8f5f4;
	margin: 24px 0px;

	@media (max-width: 992px) {
		display: block;
		grid-template-columns: unset;

		padding: 20px;
	}
`;

const SubscriptionContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
	justify-content: start;
	align-items: start;
	a,
	a:hover {
		color: #000;
		text-decoration: none;
	}

	@media (max-width: 992px) {
		padding-bottom: 20px;
		border-right: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}
`;

const Divider = styled.div`
	width: 1px;
	height: 100%;
	background: rgba(0, 0, 0, 0.05);
`;

const MonthlyAllowanceContainer = styled.div<{ cancelled: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-left: 40px;
	justify-content: start;
	align-items: start;
	opacity: ${({ cancelled }) => cancelled && 0.5};
	@media (max-width: 992px) {
		padding-left: 0px;
		padding-top: 32px;
	}
`;

const NumberH1 = styled.h1`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 48px;
	line-height: 57.6px;
	color: #191a1c;
	min-width: 33px;
	max-width: 132px;

	font-variant-numeric: tabular-nums;
	border: 0;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&focus {
		border: revert;
	}
`;

const Text = styled.p`
	font-family: 'Roobert Medium';
	font-size: 24px;
	line-height: 28.8px;
	color: #5b6166;
	padding-left: 8px;
`;

const SubButton = styled.button<{ width?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-family: 'Roobert Semi Bold';
	font-size: 14px;
	line-height: 160%;
	border: none;
	height: 32px;
	width: ${({ width = '180px' }) => width};
	padding: 4px 12px;
	background: rgba(25, 26, 28, 0.05);
	border-radius: 3px;
`;

const CancelledText = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 87px;
	height: 30px;
	background: #fae1e7;
	color: #cc1440;
	font-family: 'Roobert Semi Bold';
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
`;

const spinnerFrames = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled(BiLoaderAlt)`
	animation: ${spinnerFrames} 1s linear infinite;
`;

export default TeamBillingWrapper;
