/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation, Link, useHistory, Route, Switch } from 'react-router-dom';
import { useMergeLink } from '@mergeapi/react-merge-link';
import config from '../../config';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import Loading from '../utilities/Loading';
import { useClient } from '../../hooks';
import IntegrationsCard from './ManageTeam/IntegrationsCard';
import { useUserContext } from '../../contexts/userContext';
import AssignBudgetModal from './ManageTeam/AssignBudget/AssignBudgetModal';
import Modal from '../../styles/shared/Modal';
import Button from '../../styles/shared/Button/Button';
import BudgetSelector from '../subscription/BudgetSelector';
import useCurrencyFormatter from '../../utils/useCurrencyFormatter';
import InvitesTable, { Invite } from './ManageTeam/InvitesTable';
import TeamMembersTable, { TeamMember } from './ManageTeam/TeamMembersTable';
import AddMemberToTeam from './ManageTeam/AddMember/AddMemberToTeam';
import CSVUploadModal from './ManageTeam/AddMember/CSVUploadModal';

const StyledLoading = () => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '80vh',
			width: '100%',
			textAlign: 'center',
		}}
	>
		<Loading />
	</div>
);

const ManageTeamWrapper = ({
	team,
	handleUpdate,
	setTeamMembers,
}: {
	team: {
		id: number;
		currency: string;
		budget: number;
		fees: boolean;
		integratedHris: string;
		teamMembers: TeamMember[];
	};
	setTeamMembers: (
		update: (prevTeamMembers: TeamMember[]) => TeamMember[]
	) => void;
	handleUpdate: () => {};
}) => {
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	const changeRoleUserId = +(params.get('change-role') || 0);
	const changePlanUserId = +(params.get('change-plan') || 0);
	const removeUserId = +(params.get('remove') || 0);
	const customBudgetUserId = +(params.get('custom-budget') || 0);
	const assignBudgets = !!params.get('assign-budgets');
	const deleteBudgetUserId = +(params.get('delete-budgets-user') || 0);
	const deleteBudgetBudgetId = +(params.get('delete-budgets-budget') || 0);
	const remindAll = !!params.get('remind-all');
	const cancelInviteId = +(params.get('cancel-invite') || 0);
	const inviteMember = params.get('invite-member');
	const [selections, setSelections] = useState<Record<number, boolean>>({});
	const [customBudget, setCustomBudget] = useState('');
	const [budgetMembers, setBudgetMembers] = useState<Record<
		number,
		{
			name: string;
			budgetAmount: number;
			memberAmount: number;
			budgetId: number;
		}[]
	> | null>(null);
	const [teamBudgets, setTeamBudgets] = useState<any[]>([]);
	const [invites, setInvites] = useState<Invite[] | null>(null);

	const { loading } = useAuth0();
	const { userData, updateUserData } = useUserContext();

	const [mergeShortLivedToken, setMergeShortLivedToken] = useState(null);
	const [firstTimeMerge, setFirstTimeMerge] = useState(false);
	const currencyFormatter = useCurrencyFormatter();
	const history = useHistory();

	const client = useClient();

	const getBudgetMembers = async () => {
		const { data } = await client.get(
			`${config.teams.HOST}/teams/budget-members/${team.id}`
		);
		setBudgetMembers(data);
	};

	const getTeamBudgets = async () => {
		const { data } = await client.get(
			`${config.teams.HOST}/teams/budgets/${team.id}`
		);
		setTeamBudgets(data);
	};

	const getInvites = async () => {
		const { data } = await client.get(
			`${config.teams.HOST}/teams/invites/${team.id}`
		);
		setInvites(data);
	};

	useEffect(() => {
		try {
			getBudgetMembers();
			getTeamBudgets();
			getInvites();
		} catch (e) {
			console.log(e);
		}
	}, [client]);

	const handleMergeAuth = useCallback(async () => {
		if (!team.fees) {
			try {
				const response = await client.get(
					`${config.teams.HOST}/teams/merge/initiate-merge-token/${team.id}`
				);
				setMergeShortLivedToken(response.data.shortLivedToken);
			} catch (err) {
				console.error(err);
			}
		}
	}, []);

	const onSuccess = useCallback(async (publicSuccessToken) => {
		try {
			await client.post(
				`${config.teams.HOST}/teams/merge/store-merge-token/${team.id}`,
				{
					publicSuccessToken,
				}
			);
			setFirstTimeMerge(true);
			handleUpdate();
		} catch (err) {
			console.error(err);
		}
	}, []);

	const { open, isReady } = useMergeLink({
		linkToken: mergeShortLivedToken || '',
		onSuccess,
	} as any);

	useEffect(() => {
		try {
			handleMergeAuth();
		} catch (err) {
			console.error(err);
		}
	}, [handleMergeAuth]);

	const handleFirstTimeMerge = () => {
		setFirstTimeMerge(false);
	};

	const updateTeamMember = (
		userId: number,
		update: (teamMember: TeamMember) => TeamMember
	) => {
		setTeamMembers((prevTeamMembers) =>
			prevTeamMembers.map((teamMember) =>
				teamMember.userId === userId ? update(teamMember) : teamMember
			)
		);
	};

	const updateRewarder = async (userId: number, rewarder: boolean) => {
		updateTeamMember(userId, (teamMember) => ({
			...teamMember,
			rewarder,
		}));
		try {
			await client.put(
				`${config.teams.HOST}/teams/${team.id}/rewarder/${userId}`,
				{
					rewarder,
				}
			);
		} catch (err) {
			console.error(err);
			handleUpdate();
		}
	};

	const updatePlanStatus = async (userId: number, on: boolean) => {
		updateTeamMember(userId, (teamMember) => ({
			...teamMember,
			subStatus: on ? 'active' : 'inactive',
			budgetCount: 0,
		}));
		if (!on) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			setSelections(({ [userId]: sel, ...rest }) => rest);
			setBudgetMembers((prevBudgetMembers) => {
				if (!prevBudgetMembers) return null;
				const newBudgetMembers = { ...prevBudgetMembers };
				delete newBudgetMembers[userId];
				return newBudgetMembers;
			});
		}
		try {
			await client.put(`${config.teams.HOST}/teams/${team.id}/plan/${userId}`, {
				on,
			});
			if (userId === userData.id) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
		}
	};

	const updateRole = async (userId: number, admin: boolean) => {
		updateTeamMember(userId, (teamMember) => ({
			...teamMember,
			role: admin ? 'admin' : 'employee',
		}));
		try {
			await client.put(`${config.teams.HOST}/teams/${team.id}/role/${userId}`, {
				admin,
			});
			if (userId === userData.id) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
		}
	};

	const remove = async (userId: number, clawback: boolean) => {
		setTeamMembers((prevTeamMembers) =>
			prevTeamMembers.filter((teamMember) => teamMember.userId !== userId)
		);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setSelections(({ [userId]: sel, ...rest }) => rest);
		try {
			await client.put(
				`${config.teams.HOST}/teams/${team.id}/remove/${userId}`,
				{
					clawback,
				}
			);
			if (clawback || userId === userData.id) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
		}
	};

	const changeCustomBudget = async (userId: number, budget: number) => {
		updateTeamMember(userId, (teamMember) => ({
			...teamMember,
			budget: budget === team.budget ? null : budget,
		}));
		try {
			await client.put(
				`${config.teams.HOST}/teams/${team.id}/budget/${userId}`,
				{ budget }
			);
			if (userId === userData.id) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
		}
	};

	const sendReminder = async (inviteId: number) => {
		setInvites((prevInvites) =>
			prevInvites!.map((invite) =>
				invite.inviteId === inviteId
					? {
							...invite,
							reminder: new Date(),
					  }
					: invite
			)
		);
		try {
			await client.put(`${config.teams.HOST}/teams/invites/${inviteId}`);
		} catch (err) {
			console.error(err);
			getInvites();
		}
	};

	const sendRemindAll = async () => {
		setInvites((prevInvites) =>
			prevInvites!.map((invite) => ({
				...invite,
				reminder: new Date(),
			}))
		);
		try {
			await client.put(`${config.teams.HOST}/teams/invites/all/${team.id}`);
		} catch (err) {
			console.error(err);
			getInvites();
		}
	};

	const cancelInvite = async (id: number) => {
		setInvites((prevInvites) =>
			prevInvites!.filter(({ inviteId }) => inviteId !== id)
		);
		try {
			await client.delete(`${config.teams.HOST}/teams/invites/${id}`);
			updateUserData();
		} catch (err) {
			console.error(err);
			getInvites();
		}
	};

	if (loading || !team.teamMembers.length) {
		return <StyledLoading />;
	}

	const handleDeleteBudget = async (budgetId: number, userId: number) => {
		let budgetCount = 0;
		setBudgetMembers((prevBudgetMembers) => {
			const newBudgetMembers = { ...prevBudgetMembers };

			const newMemberBudgets = newBudgetMembers[userId].filter(
				(bId) => bId.budgetId !== budgetId
			);
			newBudgetMembers[userId] = newMemberBudgets;
			budgetCount = newBudgetMembers[userId].length;
			return newBudgetMembers;
		});

		setTeamMembers((prevTeamMembers) =>
			prevTeamMembers.map((teamMember) => {
				return {
					...teamMember,
					budgetCount:
						teamMember.userId === userId ? budgetCount : teamMember.budgetCount,
				};
			})
		);

		try {
			await client.delete(
				`${config.teams.HOST}/teams/budgets/${budgetId}/${userId}`
			);
			if (userId === userData.id) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
			getBudgetMembers();
		}
	};

	const handleAssignBudgets = async (budgetIds: number[]) => {
		const teamBudgetLookup = teamBudgets.reduce(
			(lookup, budget) => ({ ...lookup, [budget.id]: budget }),
			{}
		);

		const selectedUsers = Object.keys(selections).filter(
			(userId) => !!selections[+userId]
		);

		const budgetCounts = {} as any;
		setBudgetMembers((prevBudgetMembers) => {
			const newBudgetMembers = { ...prevBudgetMembers };

			selectedUsers.forEach((userId) => {
				if (!newBudgetMembers[+userId]) {
					newBudgetMembers[+userId] = [];
				}

				const budgetMemberLookup: any = newBudgetMembers[+userId].reduce(
					(lookup, member) => ({ ...lookup, [member.budgetId]: true }),
					{}
				);

				newBudgetMembers[+userId] = [
					...newBudgetMembers[+userId],
					...budgetIds
						.filter((budgetId) => !budgetMemberLookup[budgetId])
						.map((budgetId) => {
							const { name, amount, id } = teamBudgetLookup[budgetId];
							return {
								budgetId: id,
								name,
								memberAmount: amount,
								budgetAmount: amount,
							};
						}),
				];
				budgetCounts[+userId] = newBudgetMembers[+userId].length;
			});

			return newBudgetMembers;
		});

		setTeamMembers((prevTeamMembers) =>
			prevTeamMembers.map((teamMember) => {
				if (!selections[teamMember.userId]) {
					return teamMember;
				}
				return {
					...teamMember,
					budgetCount: budgetCounts[teamMember.userId],
				};
			})
		);
		setSelections({});
		try {
			await client.post(
				`${config.teams.HOST}/teams/budgets/member/${team.id}`,
				{
					budgetIds,
					userIds: selectedUsers.map((userId) => +userId),
				}
			);
			if (selections[userData.id]) updateUserData();
		} catch (err) {
			console.error(err);
			handleUpdate();
			getBudgetMembers();
		}
	};

	const changeRoleUser = changeRoleUserId
		? team.teamMembers.find(({ userId }) => userId === changeRoleUserId)
		: null;
	const changePlanUser = changePlanUserId
		? team.teamMembers.find(({ userId }) => userId === changePlanUserId)
		: null;
	const removeUser = removeUserId
		? team.teamMembers.find(({ userId }) => userId === removeUserId)
		: null;
	const customBudgetUser = customBudgetUserId
		? team.teamMembers.find(({ userId }) => userId === customBudgetUserId)
		: null;
	const deleteBudgetUser = deleteBudgetUserId
		? team.teamMembers.find(({ userId }) => userId === deleteBudgetUserId)
		: null;
	const deleteBudgetBudget = deleteBudgetBudgetId
		? teamBudgets.find((budget) => budget.id === deleteBudgetBudgetId)
		: null;
	const cancelInvitation = cancelInviteId
		? invites?.find((invite) => invite.inviteId === cancelInviteId)
		: null;

	const closeModal = () => {
		const p = new URLSearchParams(search);
		p.delete('change-role');
		p.delete('change-plan');
		p.delete('remove');
		p.delete('custom-budget');
		p.delete('assign-budgets');
		p.delete('delete-budgets-user');
		p.delete('delete-budgets-budget');
		p.delete('remind-all');
		p.delete('cancel-invite');
		p.delete('invite-member');
		history.push(`${pathname}?${p.toString()}`, {
			scroll: false,
		});
	};
	return (
		<>
			<ManageTeam>
				<div style={{ display: 'flex' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<h2>Manage Team</h2>
						<p>Easily add and manage team members</p>
					</div>
					<div style={{ flex: 1 }} />
					<div className="filter">
						<div>
							<Link
								to={{
									pathname: `/team/${team.id}`,
									state: { scroll: false },
								}}
								className={
									!pathname.endsWith('/invites') ? 'selected' : undefined
								}
							>
								Active users
							</Link>
							<Link
								to={{
									pathname: `/team/${team.id}/invites`,
									state: { scroll: false },
								}}
								className={
									pathname.endsWith('/invites') ? 'selected' : undefined
								}
							>
								Pending invites
							</Link>
						</div>
					</div>
				</div>
				<Switch>
					<Route
						path="/team/:teamId/invites"
						render={() => (
							<InvitesTable
								team={team}
								invites={invites}
								sendReminder={sendReminder}
							/>
						)}
					/>
					<Route
						path="/team/:teamId/"
						render={() => (
							<TeamMembersTable
								team={team}
								selections={selections}
								budgetMembers={budgetMembers}
								teamBudgets={teamBudgets}
								setSelections={setSelections}
								updateRewarder={updateRewarder}
								setCustomBudget={setCustomBudget}
							/>
						)}
					/>
				</Switch>
			</ManageTeam>
			<div style={{ paddingTop: 40 }}>
				<h2>Integrations</h2>
				<IntegrationsCard
					firstTimeMerge={firstTimeMerge}
					handleFirstTimeMerge={() => handleFirstTimeMerge()}
					integrationName={team.integratedHris}
					handleSetup={open}
					team={team}
					handleUpdate={(refreshShortLiveToken: boolean) => {
						if (refreshShortLiveToken) handleMergeAuth();
						getInvites();
						handleUpdate();
					}}
					slotAvailable={mergeShortLivedToken && isReady}
				/>
			</div>
			{assignBudgets && (
				<AssignBudgetModal
					onClose={closeModal}
					currency={team.currency}
					onSubmit={handleAssignBudgets}
					isOpen
					budgets={teamBudgets}
				/>
			)}
			{changeRoleUser && (
				<Modal open onClose={closeModal}>
					<Confirm
						onCancel={closeModal}
						onConfirm={() => {
							updateRole(changeRoleUserId, changeRoleUser.role === 'employee');
							closeModal();
						}}
					>
						<h2>{`Change Role to ${
							changeRoleUser.role === 'admin' ? 'Employee' : 'Admin'
						}`}</h2>
						<p>{`Are you sure you want to change the role of ${
							changeRoleUser.name || changeRoleUser.email
						}?`}</p>
					</Confirm>
				</Modal>
			)}
			{deleteBudgetUser && deleteBudgetBudget && (
				<Modal open onClose={closeModal}>
					<Confirm
						onCancel={closeModal}
						onConfirm={() => {
							handleDeleteBudget(deleteBudgetBudgetId, deleteBudgetUserId);
							closeModal();
						}}
					>
						<h2>{`Delete '${deleteBudgetBudget.name}' budget?`}</h2>
						<p>
							{deleteBudgetUser.name || deleteBudgetUser.email} will no longer
							have access to this budget.
						</p>
						<p>Are you sure?</p>
					</Confirm>
				</Modal>
			)}
			{changePlanUser && (
				<Modal open onClose={closeModal}>
					<Confirm
						onCancel={closeModal}
						onConfirm={() => {
							updatePlanStatus(
								changePlanUserId,
								changePlanUser.subStatus === 'inactive'
							);
							closeModal();
						}}
					>
						<h2>
							{`
							${changePlanUser.subStatus === 'active' ? 'Remove from' : 'Add to'}
							Plan`}
						</h2>
						<p>{`Are you sure you want to ${
							changePlanUser.subStatus === 'active' ? 'remove' : 'add'
						} ${changePlanUser.name || changePlanUser.email} ${
							changePlanUser.subStatus === 'active' ? 'from' : 'to'
						} the plan?`}</p>
						{changePlanUser.subStatus === 'active' && (
							<p className="info">
								Off Plan users have 30 days to spend their remaining points
								before their accounts are deactivated and the points will no
								longer be available
							</p>
						)}
					</Confirm>
				</Modal>
			)}
			{removeUser && (
				<Modal open onClose={closeModal}>
					<Confirm
						confirmText="Remove"
						destructive
						onCancel={closeModal}
						onConfirm={(form) => {
							remove(removeUserId, form.clawback.checked);
							closeModal();
						}}
					>
						<h2>Remove from Team</h2>
						<p>{`Are you sure you want to remove ${
							removeUser.name || removeUser.email
						} from your team?`}</p>
						<p>
							<input type="checkbox" id="clawback" />
							<label htmlFor="clawback">
								Return their points to the team wallet
							</label>
						</p>
					</Confirm>
				</Modal>
			)}
			{customBudgetUser && (
				<Modal open onClose={closeModal}>
					<Confirm
						confirmText="Save"
						onCancel={closeModal}
						onConfirm={() => {
							changeCustomBudget(customBudgetUserId, +customBudget);
							closeModal();
						}}
					>
						<h2>{`Set custom budget for ${
							customBudgetUser.name || customBudgetUser.email
						}`}</h2>
						<BudgetSelector
							points={customBudget}
							setPoints={setCustomBudget}
							teamBudget={team.budget}
							minPoints={0}
						/>
						<p>
							Equals {currencyFormatter(+customBudget, team.currency || 'gbp')}{' '}
							per month
						</p>
					</Confirm>
				</Modal>
			)}
			{remindAll && (
				<Modal open onClose={closeModal}>
					<Confirm
						onCancel={closeModal}
						onConfirm={() => {
							sendRemindAll();
							closeModal();
						}}
					>
						<h2>Remind All</h2>
						<p>
							Are you sure you want to send a reminder to everyone who still has
							a pending invitation?
						</p>
					</Confirm>
				</Modal>
			)}
			{cancelInvitation && (
				<Modal open onClose={closeModal}>
					<Confirm
						onCancel={closeModal}
						onConfirm={() => {
							cancelInvite(cancelInviteId);
							closeModal();
						}}
					>
						<h2>Cancel Invite</h2>
						<p>{`Are you sure you want to cancel invite for ${cancelInvitation.email}?`}</p>
					</Confirm>
				</Modal>
			)}
			{inviteMember === 'email' && (
				<AddMemberToTeam
					show
					setShow={(show: boolean) => {
						if (!show) closeModal();
					}}
					team={team}
					handleUpdate={() => {
						getInvites();
						updateUserData();
					}}
				/>
			)}
			{inviteMember === 'csv' && (
				<CSVUploadModal
					show
					setShowCSV={(show: boolean) => {
						if (!show) closeModal();
					}}
					team={team}
					handleUpdate={() => {
						getInvites();
						updateUserData();
					}}
				/>
			)}
		</>
	);
};

const Confirm = ({
	children,
	confirmText = 'Confirm',
	destructive = false,
	onCancel,
	onConfirm,
}: {
	children: any;
	confirmText?: string;
	destructive?: boolean;
	onCancel: () => void;
	onConfirm: (form: HTMLFormElement) => void;
}) => (
	<ModalContainer>
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onConfirm(e.currentTarget);
			}}
		>
			{children}
			<div className="buttons">
				<Button
					state="default"
					buttonType="hollow"
					type="button"
					size="small"
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					state="default"
					buttonType={!destructive ? 'primary' : 'destructive'}
					size="small"
				>
					{confirmText}
				</Button>
			</div>
		</form>
	</ModalContainer>
);

const ManageTeam = styled.div`
	> div {
		align-items: center;
	}
	h2 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 28px;
		line-height: 35px;
		padding-top: 45px;
		+ p {
			font-family: 'TTInterfaces Medium';
			font-size: 21px;
			line-height: 31.5px;
			opacity: 40%;
			padding-bottom: 30px;
			margin: 0;
		}
	}
	.filter > div {
		display: flex;
		align-items: center;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		height: 40px;
	}
	.filter > div {
		a {
			font-family: 'TTInterfaces DemiBold';
			font-size: 14.5px;
			line-height: 20px;
			white-space: nowrap;
			padding: 10px 16px;
			color: rgb(29, 41, 57);
			text-decoration: none;
			display: flex;
			background: linear-gradient(0deg, #ffffff, #ffffff);
		}
		a:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border: 1px solid rgb(208, 213, 221);
		}
		a:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border: 1px solid rgb(208, 213, 221);
			border-left: 0;
		}
		a.selected {
			background: linear-gradient(0deg, #f9fafb, #f9fafb);
		}
	}
	@media (max-width: 992px) {
		h2 {
			padding-top: 20px;
			+ p {
				padding-bottom: 20px;
			}
		}
		> div {
			flex-direction: column;
			align-items: flex-start;
		}
		.filter {
			width: 100%;
			padding-bottom: 25px;
			a {
				width: 50%;
				display: flex;
				justify-content: center;
			}
		}
	}
`;

const ModalContainer = styled.div`
	max-width: 475px;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px 45px;
	h2 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		line-height: 30px;
		color: rgb(16, 24, 40);
	}
	p {
		font-family: 'TTInterfaces Regular';
		font-size: 16px;
		line-height: 23px;
		color: rgb(71, 84, 103);
	}
	p.info {
		font-style: italic;
	}
	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 16px;
		padding-top: 24px;
	}
	input {
		accent-color: rgb(25, 26, 28);
	}
	label {
		margin-left: 8px;
	}
`;

export default ManageTeamWrapper;
