import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { useClient, useCurrentTeam } from '../../hooks';
import { useUserContext } from '../../contexts/userContext';
import { Circle, Container } from '../../styles/ProductStyles';
import { H1, H5, ParagraphLarge } from '../../styles/StylesV3Main';
import { handleBackNavigation } from '../utilities/Functions';
import CreateJunoCardForm from './CreateJunoCardForm';
import config from '../../config';
import junoFlexCard from './juno-flex-card.jpeg';

const BackgroundContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
`;

const ColumnContainer = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 32px;
`;

const FormContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: #fff;
	padding: 48px;
`;

const CardImage = styled.img`
	width: 100%;
	height: 360px;
	object-fit: cover;
	object-position: center;
	padding-bottom: 20px;
`;

const H5TextSecondary = styled(H5)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const ParaTextSecondary = styled(ParagraphLarge)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	padding-right: 0%;
	a,
	a:hover {
		color: inherit;
		text-decoration: underline;
	}
	@media (max-width: 992px) {
		padding-right: 0%;
	}
`;

const CreateJunoCard = () => {
	const client = useClient();
	const history = useHistory();
	const currentTeam = useCurrentTeam();
	const { userData, updateUserData } = useUserContext();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [createdResponse, setCreatedResponse] = useState(null);
	const [showSnackBar, setShowSnackBar] = useState(false);

	const onFormSubmit = async (values) => {
		setIsSubmitting(true);
		try {
			const response = await client.post(
				`${config.platform.HOST}/payments/authorized/cards/${userData.currentTeam}`,
				{
					cardholder: {
						fullName: values.fullName,
						addressLine1: values.billing.line1,
						addressLine2: values.billing.line2 || '',
						townOrCity: values.billing.city,
						postalCode: values.billing.postalCode,
						country: values.billing.country,
						phoneNumber: values.phone,
					},
				}
			);
			await updateUserData();
			setCreatedResponse({ stripeCardholderId: response.data.cardholder });
		} catch (err) {
			setIsSubmitting(false);
			setShowSnackBar(true);
			// eslint-disable-next-line no-console
			console.error(err.message);
		}
	};

	const flexCardInfo = !currentTeam.receiptRequired
		? `Juno Flex Cards® are a great way to give you the optimal freedom that you deserve. You can use this card for paying for your subscriptions like Headspace, Calm, Netflix, Games or others. Next to that you can use it for doing wellbeing purchases online. The available balance on your card is connected to the amount of points that you have in your wallet.`
		: `The Juno Flex Card® gives you ultimate freedom over how you use your Juno Points. It is only to be used to pay for wellbeing goods and services, and you will be prompted via email to submit a receipt after every transaction you make, which will be reviewed by the Juno team. Receipts won’t be shared with your company unless purchases are deemed to be outside of the scope of wellbeing.`;

	return (
		<BackgroundContainer>
			<Snackbar
				open={showSnackBar}
				autoHideDuration={10000}
				onClose={() => setShowSnackBar(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={() => setShowSnackBar(false)}
					severity="error"
					sx={{ width: '100%' }}
				>
					We are so sorry! We are unable to create a card for you at the moment.
					Please send us a message via the chat or an email to
					support@withjuno.com
				</Alert>
			</Snackbar>
			{!createdResponse && (
				<Container>
					<Row noGutters className="justify-content-start align-items-start">
						<Circle size="48px" onClick={() => handleBackNavigation(history)}>
							<MdArrowBack size={20} />
						</Circle>
					</Row>
					<Row>
						<Col sm={12} md={6}>
							<ColumnContainer>
								<>
									<CardImage loading="lazy" src={junoFlexCard} />
									<H5TextSecondary>Juno Card</H5TextSecondary>
									<H1 className="mt-2">Juno Flex Card®</H1>
									<ParaTextSecondary className="mt-3">
										{userData.currentTeam !== 737 ? (
											flexCardInfo
										) : (
											<>
												<span>
													Hi team! We are now transitioning to Juno Flex Card
													for your health and well-being benefits. Your Flex
													Card allowance will be the same as the previous
													system: you will receive 166 points each month (note
													if you signed up for the SafetyWing insurance, your
													balance may be different). Just a quick reminder to
													follow{' '}
												</span>
												<a
													href="https://docs.google.com/document/d/1BIZythFSljUHIhLFY8iAswuAzgypqXy6OKTcw0HxUtM/edit"
													target="_blank"
													rel="noreferrer"
												>
													the guide
												</a>
												<span>
													. Please pay close attention to the &quot;permitted
													categories&quot; for services that are eligible via
													Juno. Each month Juno will run a quick look-over to
													make sure all transactions are within the approved
													categories. Please reach out to{' '}
												</span>
												<a href="mailto:support@withjuno.com">
													support@withjuno.com
												</a>
												<span>
													{' '}
													if you have any questions on how to set up and use the
													card.
												</span>
											</>
										)}
									</ParaTextSecondary>
								</>
							</ColumnContainer>
						</Col>
						<Col sm={12} md={6}>
							<ColumnContainer>
								<FormContainer>
									<H1>Request a Flex Card®</H1>
									<CreateJunoCardForm
										onSubmit={onFormSubmit}
										loading={isSubmitting}
									/>
								</FormContainer>
							</ColumnContainer>
						</Col>
					</Row>
				</Container>
			)}
		</BackgroundContainer>
	);
};

export default CreateJunoCard;
